<template>
  <div>
    <div style="margin: 20px 0 0 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="role-main">
      <div class="top">
        <div>
          <Button type="primary" @click="add">{{ $t('dataset_map_add') }}</Button>
          &nbsp;
          <!-- <Button type="info" @click="toAiGenerateList">AI生成考点</Button> -->
          &nbsp;
          <!--      <Button type="info" @click="toNodeList">{{$t('dataset_map_toNodeList')}}</Button>-->
          <!-- <Button type="info" @click="toAiMatchList">AI匹配考点</Button> -->
          &nbsp;
        </div>

        <div class="top-right">
          <Input v-model="keyword" :placeholder="$t('project_search_project_nameid')" clearable search
            :enter-button="true" @on-search="changeKeyword" />
        </div>
      </div>
      <div class="search">
        <div class="search-item">
          <div class="name">
            类型:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="typeId == item.id ? 'active' : ''" v-for="(item, index) in selectDataTabList"
                :key="index + 'type'" @click="changeSearch({ id: index }, 'typeId')">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="search-item">
          <div class="name">
            一级分类:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="curCategoryId == item.id ? 'active' : ''" v-for="item in categoryList"
                :key="item.id + 'category'" @click="changeSearch(item, 'curCategoryId')">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div class="search-item" v-if="secondCategoryList.length > 1">
          <div class="name">
            二级分类:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="cursecondCategoryId == item.id ? 'active' : ''" v-for="item in secondCategoryList"
                :key="item.id + 'secondCategory'" @click="changeSearch(item, 'cursecondCategoryId')">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <Table border :columns="columns" :loading="loading" :data="data"></Table>
      <div class="page">
        <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total
          show-sizer show-elevator></Page>
      </div>
      <DeleModal :status="deleModalStatus" :txt="$t('dataset_map_dele_tip')" :loading="modalLoading" @cancel="cancelDele"
        @confirm="confirmDele"></DeleModal>

      <!--    <Modal-->
      <!--        v-model="modalStatus"-->
      <!--        :title="modalTit"-->
      <!--        @on-cancel="cancel"-->
      <!--        >-->
      <!--      <Form :model="formItem" ref="formCustom" :label-width="80" :rules="ruleValidate">-->
      <!--        <FormItem :label="$t('dataset_map_table_name')" prop="name">-->
      <!--          <Input :disabled="type == 'check'" v-model="formItem.name" :placeholder="$t('dataset_map_add_name_tip')"></Input>-->
      <!--        </FormItem>-->
      <!--        <FormItem :label="$t('dataset_map_table_data_count')" prop="data_count">-->
      <!--          <Input :disabled="type == 'check'" v-model="formItem.data_count" :placeholder="$t('dataset_map_add_data_count_tip')"></Input>-->
      <!--        </FormItem>-->
      <!--        <FormItem :label="$t('dataset_map_table_desc')" prop="desc">-->
      <!--          <Input :disabled="type == 'check'"  v-model="formItem.desc" :placeholder="$t('dataset_map_add_desc_tip')"></Input>-->
      <!--        </FormItem>-->

      <!--      </Form>-->
      <!--      <div slot="footer">-->
      <!--        <Button @click="cancel">取消</Button>-->
      <!--        <Button type="primary" @click="ok" :loading="addBtnLoading" v-if="type != 'check'" >确定</Button>-->
      <!--      </div>-->
      <!--    </Modal>-->
    </div>
    <Modal v-model="selectDataModal" :title="$t('project_select_type')" :footer-hide="true" :scrollable="true"
      class="select-data-modal" width="1000px">
      <div class="tab-list">
        <div class="tab-list-top">
          <div>
            <div :class="selectDataTabList[curSelectDataTabIndex].id == item.id ? 'active' : ''"
              v-for="(item, index) in selectDataTabList" :key="item.id" @click="changeTab(index)">{{ item.name }}</div>
          </div>

        </div>
        <div class="select-data-cont" v-if="selectDataModal">
          <!--          <CategoryData></CategoryData>-->
          <AttentationCategoryData :type="1" :isSuper="true" :dataType="selectDataTabList[curSelectDataTabIndex].id"
            @create="createMap"></AttentationCategoryData>
          <!--          <component :is="selectDataTabList[curSelectDataTabIndex].componentName" :type="1" :dataType="selectDataTabList[curSelectDataTabIndex].id"  @create="createMap"></component>-->
        </div>
      </div>
    </Modal>
    <Modal v-model="uploadModal" title="导入知识图谱" width="1000px">

      <p style="margin-bottom: 20px">导入文件格式(".xlsx",".xls")</p>
      <Upload :action="constant.URL + '/uploadfile/upload'" :data="{
        type: 0
      }" accept=".xlsx,.xls" :headers="{
  'access-token': Cookies.get(constant.tokenName)
}" :on-success="fileSuccess" :show-upload-list="false">
        <Button icon="ios-cloud-upload-outline">选择文件</Button>
        <span style="padding-left: 20px">{{ uploadFile ? uploadFile : '未选择文件' }}</span>
      </Upload>
      <div slot="footer">
        <Button @click="cancelUpload">取消</Button>
        <Button type="primary" @click="confirmUpload">确定</Button>
      </div>
    </Modal>
    <AddKnowledgeGraph :show="drawerShow" @close="drawerShow=false"/>

  </div>
</template>

<script>
import util from '@/utils/tools.js';
import DeleModal from '../../components/deleteModal.vue';
// import CategoryData from "./components/map/categoryData"
import AttentationCategoryData from "@/views/trainingcamp/components/common/categoryData";
import AddKnowledgeGraph from "./components/addKnowledgeGraph/addKnowledgeGraph.vue"
import Cookies from "js-cookie";
export default {
  name: "datasetMap",
  data() {
    return {
      Cookies: Cookies,
      keyword: '',
      columns: [
        {
          title: this.$t('dataset_map_table_index'),
          key: 'id',
          width:80
        },
        {
          title: this.$t('dataset_map_table_name'),
          key: 'name',
          minWidth: 200,
          render: (h, params) => {
            return h('router-link', {
              props: {
                to: {
                  name: 'map-detail',
                  params: {
                    id: params.row.id,
                    tab: 'chart'
                  }
                }
              },
              style: {
                color: '#2db7f5',
              }
            }, params.row.name);
          }
        },
        {
          title: '状态',
          minWidth: 80,
          render: (h, params) => {
            return h('span', {
            }, this.statuses[params.row.status]);
          }
        },
        {
          title: '题库',
          minWidth: 150,
          render: (h, params) => {
            return h('span', {
            }, params.row.category && params.row.category.name + '(ID:' + params.row.category.id + ')');
          }
        },
        {
          title: '题库数据量',
          minWidth: 100,
          render: (h, params) => {
            return h('span', {
            }, params.row.category ? params.row.category.data_count : 0);
          }
        },
        {
          title: '匹配状态',
          minWidth: 100,
          render: (h, params) => {
            return h('span', {
            }, params.row.match ? this.match_statuses[params.row.match.status] : '未执行');
          }
        },
        {
          title: '图谱数据量',
          minWidth: 100,
          render: (h, params) => {
            return h('span', {
            }, params.row.data_count);
          }
        },
        {
          title: '创建人',
          minWidth: 150,
          render: (h, params) => {
            return h('span', {
            }, params.row.creator ? params.row.creator.nickname + '(' + params.row.creator.id + ')' : '');
          }
        },
        {
          title: this.$t('dataset_map_create_date'),
          key: 'name',
          minWidth: 150,
          render: (h, para) => {
            return h('span', util.timeFormatter(
              new Date(+para.row.created_at * 1000), 'MM-dd hh:mm'
            )
            );
          }
        },
        {
          title: this.$t('dataset_map_update_date'),
          key: 'name',
          minWidth: 150,
          render: (h, para) => {
            return h('span', util.timeFormatter(
              new Date(+para.row.updated_at * 1000), 'MM-dd hh:mm'
            )
            );
          }
        },
        // {
        //   title: this.$t('dataset_map_table_data_count'),
        //   key: 'data_count',
        // },
        // {
        //   title: this.$t('dataset_map_table_desc'),
        //   key: 'desc',
        // },
        // {
        //   title: this.$t('dataset_map_table_op'),
        //   minWidth:80,
        //   render: (h, params) => {
        //     return h('div', [
        //       h('Button', {
        //         props: {
        //           type: 'primary',
        //           size: 'small'
        //         },
        //         style: {
        //           marginRight: '5px'
        //         },
        //         on: {
        //           click: () => {
        //             this.leadingIn(params.row)
        //           }
        //         }
        //       }, '导入图谱章节'),
        //       // h('Button', {
        //       //   props: {
        //       //     type: 'primary',
        //       //     size: 'small'
        //       //   },
        //       //   style: {
        //       //     marginRight: '5px'
        //       //   },
        //       //   on: {
        //       //     click: () => {
        //       //       this.refreshCount(params.row)
        //       //     }
        //       //   }
        //       // }, '刷新匹配题量'),
        //       h('Button', {
        //         props: {
        //           type: 'primary',
        //           size: 'small'
        //         },
        //         style: {
        //           marginRight: '5px'
        //         },
        //         on: {
        //           click: () => {
        //             this.startMatch(params.row)
        //           }
        //         }
        //       }, '开始匹配'),
        //       // h('Button', {
        //       //   props: {
        //       //     type: 'primary',
        //       //     size: 'small'
        //       //   },
        //       //   style: {
        //       //     marginRight: '5px'
        //       //   },
        //       //   on: {
        //       //     click: () => {
        //       //       this.toMatchDataList(params.row)
        //       //     }
        //       //   }
        //       // }, '查看匹配数据'),
        //     ]);
        //   },
        // },
        {
          title: this.$t('dataset_map_table_op'),
          width: 250,
          fixed:"right",
          render: (h, params) => {
            return h('div', [
              // h('Button', {
              //   props: {
              //     type: 'primary',
              //     size: 'small'
              //   },
              //   style: {
              //     marginRight: '15px'
              //   },
              //   on: {
              //     click: () => {
              //       this.copyMap(params.row)
              //     }
              //   }
              // }, '复制'),
              h('el-button', {
                props: {
                  type: 'primary',
                  size: 'mini'
                },
                style: {
                  // marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.edit(params.row)
                  }
                }
              }, this.$t('system_edit')),
              (params.row.status == 0 || params.row.status == 2) && h('el-button', {
                props: {
                  // type: 'info',
                  size: 'mini'
                },
                style: {
                  // marginRight: '15px'
                },
                on: {
                  click: () => {
                    this.enable(params.row);
                  }
                }
              }, '上线'),
              params.row.status == 1 && h('el-button', {
                props: {
                  // type: 'info',
                  size: 'mini'
                },
                style: {
                  // marginRight: '15px'
                },
                on: {
                  click: () => {
                    this.disable(params.row);
                  }
                }
              }, '下线'),
              // h('Button', {
              //   props: {
              //     type: 'error',
              //     size: 'small'
              //   },
              //   style: {
              //     marginRight: '15px'
              //   },
              //   on: {
              //     click: () => {
              //       this.delete(params.row);
              //     }
              //   }
              // }, this.$t('system_dele')),
              h("el-select",{
                props:{
                  transfer: true,//不受表格高度影响,
                  placeholder:this.$t('codekey_manage_more'),
                  size: 'mini',
                  clearable:true,
                  value:'',
                },
                style:{
                  width:'75px',
                  marginLeft:'8px'
                },
                on:{
                  change:(event)=>{
                    if(event==="复制"){
                      this.copyMap(params.row)
                    }else if(event===this.$t('system_dele')){
                      this.delete(params.row);
                    }
                  }
                }
              },[
                h("el-option",{
                  props:{
                    value:"复制"
                  }
                },"复制"),
                h("el-option",{
                  props:{
                    value:this.$t('system_dele')
                  },
                  style:{
                    color:"red"
                  }
                },this.$t('system_dele')),
              ])
            ]);
          }
        }
      ],
      data: [],
      loading: false,
      deleteComfirmModal: true,
      deleModalStatus: false,
      modalLoading: false,
      curDeleData: {},
      page: 1,
      pageSize: 10,
      total: 0,
      modalStatus: false,
      formItem: {
        id: '',
        name: '',
        data_count: '',
        desc: '',
      },
      ruleValidate: {
        id: [
          { required: true, message: this.$t('dataset_map_add_id_tip'), trigger: 'blur' }
        ],
        name: [
          { required: true, message: this.$t('dataset_map_add_name_tip'), trigger: 'blur' }
        ],
        data_count: [
          { message: this.$t('dataset_map_add_data_count_tip'), trigger: 'blur' }
        ],
        desc: [
          { message: this.$t('dataset_map_add_desc_tip'), trigger: 'blur' },
        ],
      },
      addBtnLoading: false,
      type: '',
      modalTit: '',
      selectDataModal: false,
      selectDataTabList: [ //0分类1知识图谱2产品3题库4岗评
        // {
        //   name:'认证',
        //   id:0,
        //   componentName:'AttentationCategoryData'
        // },
        // {
        //   name:'教材',
        //   id:1,
        //   componentName:'AttentationCategoryData'
        // },
        // {
        //   name:'竞赛',
        //   id:2,
        //   componentName:'AttentationCategoryData'
        // }
      ],
      curSelectDataTabIndex: 0,
      statuses: {},
      match_statuses: {},
      uploadModal: false,
      uploadFile: '',
      uploadFileId: '',
      curData: {},
      typeId: 0,
      curCategoryId: '-1',
      cursecondCategoryId: '-1',
      categoryList: [],
      secondCategoryList: [],

      // 抽屉表单
      drawerForm: {
        type: "0",
        category_id:"-1",
        category_second_id:"-1"
      },
      // 添加知识图谱抽屉可见性
      drawerShow: false,
      // 类型数据   认证类、教材、课程
      typesDataList: [],
      // 抽屉一级分类数据
      category1List:[],
      // 抽屉二级分类数据
      category2List:[],
      // 抽屉三级分类数据
      category3List:[]
    }
  },
  components: {
    DeleModal,
    // CategoryData,
    AttentationCategoryData,
    AddKnowledgeGraph,
  },
  mounted() {
    this.getList();
    this.getCategoryList('typeId');
  },
  methods: {
    changeTab(index) {
      this.curSelectDataTabIndex = index;
    },
    changeKeyword(data) {
      this.keyword = data;
      this.getList();
    },
    add() {
      // this.selectDataModal = true;
      this.drawerShow = true;
    },
    check(data) {
      this.modalTit = this.$t('dataset_map_check');
      this.type = 'check';
      this.formItem = {
        id: data.id,
        name: data.name,
        data_count: data.data_count,
        desc: data.desc
      };
      this.modalStatus = true;
    },
    edit(data) {
      this.$router.push({
        path: `/dataset/mapCreate?id=${data.id}`,
      })
      // this.modalTit = this.$t('dataset_map_edit');
      // this.type = 'edit';
      // this.formItem = {
      //   id:data.id,
      //   name:data.name,
      //   data_count:data.data_count,
      //   desc:data.desc
      // };
      // this.modalStatus = true;
    },
    getList() {
      this.loading = true;
      let params = {
        page: this.page,
        pageSize: this.pageSize,
        keyword: this.keyword,
        site_id: -1,
        user_id: -1,
        type: this.typeId
      };
      if (this.cursecondCategoryId > -1) {
        params.category_id = this.cursecondCategoryId;
      } else if (this.curCategoryId > -1) {
        params.category_id = this.curCategoryId;
      }

      this.api.dataset.mapList(params).then((res) => {
        this.loading = false;
        if (!res.list.length && this.page > 1) {
          this.page = this.page - 1;
          this.getList();
          return;
        }
        this.data = res.list;
        this.statuses = res.statuses;
        this.match_statuses = res.match_statuses;
        this.total = Number(res.count);
      }).catch((err) => {
        this.loading = false;
      })
    },
    delete(data) {
      this.curDeleData = data;
      this.deleModalStatus = true;
    },
    cancelDele() {
      this.deleModalStatus = false;
    },
    confirmDele() {
      let data = {
        id: this.curDeleData.id
      }
      this.modalLoading = true;
      this.api.dataset.mapDelete(data).then((res) => {
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getList();
      }).catch((err) => {
        this.modalLoading = false;
      });
    },
    enable(data) {
      let params = {
        id: data.id
      }
      this.modalLoading = true;
      this.api.dataset.mapEnable(params).then((res) => {
        this.modalLoading = false;
        this.$Message.success("上线成功");
        this.getList();
      }).catch((err) => {
        this.modalLoading = false;
      });
    },
    disable(data) {
      let params = {
        id: data.id
      }
      this.modalLoading = true;
      this.api.dataset.mapDisable(params).then((res) => {
        this.modalLoading = false;
        this.$Message.success("下线成功");
        this.getList();
      }).catch((err) => {
        this.modalLoading = false;
      });
    },
    changePage(page) {
      console.log(page, 'changePage')
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    ok() {
      this.$refs.formCustom.validate((valid) => {
        if (valid) {
          let data = {
            id: this.formItem.id,
            data_count: this.formItem.data_count,
            name: this.formItem.name,
            desc: this.formItem.desc
          };
          this.addBtnLoading = true;
          if (this.formItem.id) {
            this.api.dataset.mapUpdate(data).then((res) => {
              this.addBtnLoading = false;
              this.modalStatus = false;
              this.getList();
            }).catch((err) => {
              this.addBtnLoading = false;
            })
          } else {
            this.api.dataset.mapCreate(data).then((res) => {
              this.addBtnLoading = false;
              this.modalStatus = false;
              this.page = 1;
              this.getList();
            }).catch((err) => {
              this.addBtnLoading = false;
            })
          }

        } else {
          console.log(this.modalStatus)
        }
      })
    },
    cancel() {
      this.modalStatus = false;
      this.$refs.formCustom.resetFields();
    },
    toNodeList() {
      this.$router.push({
        path: '/dataset/node',
        query: {

        }
      })
    },
    toAiTrainList() {
      this.$router.push({
        path: '/dataset/map-aitrain-list',
        query: {

        }
      })
    },
    toAiGenerateList() {
      this.$router.push({
        path: '/dataset/map-aigenerate-list',
        query: {

        }
      })
    },
    toAiMatchList() {
      this.$router.push({
        path: '/dataset/map-aimatch-list',
        query: {

        }
      })
    },
    copyMap(row) {
      let data = {
        id: row.id,
      };
      this.api.dataset.mapCopy(data).then(() => {
        this.getList();
      })
    },
    refreshCount(row) {
      let data = {
        id: row.id,
      };
      this.api.dataset.mapRefreshCount(data).then(() => {
        this.getList();
      })
    },
    toMatchDataList(row) {
      this.$router.push({
        path: '/dataset/map-aimatch-keypointlist',
        query: {
          map_id: row.id
        }
      })
    },
    startMatch(row) {
      let data = {
        map_id: row.id,
      };
      this.api.dataset.datasetMapAiMatchStart(data).then(() => {
        this.$Message.success('开始匹配');
      });
    },
    fileSuccess(response, file, fileList) {  //上传文件成功
      // console.log(response.data.info.upload_path,'dddd')
      this.uploadFile = response.data.info.name + '.' + response.data.info.file_extension;
      this.uploadFileId = response.data.info.id;
    },
    confirmUpload() {
      if (!this.uploadFileId) {
        this.$Message.warning('请选择文件～');
        return;
      }
      let params = {
        uploadfile_id: this.uploadFileId,
        map_id: this.curData.id,
        // category_id:this.curThirdCategoryId
      }
      this.api.dataset.datasetMapFileImport(params).then((res) => {
        this.$Message.success('上传成功～');
        this.getList();
        this.uploadModal = false;
      })
    },
    cancelUpload() {
      this.uploadModal = false;
    },
    leadingIn(data) {
      this.uploadModal = true;
      this.curData = data;
    },
    changeSearch(data, name) {
      this[name] = data.id;
      this.page = 1;
      if (name == 'typeId') {
        this.curCategoryId = '-1';
        this.cursecondCategoryId = '-1';
        this.getCategoryList(name);
      } else if (name == 'curCategoryId') {
        this.cursecondCategoryId = '-1';
        this.getCategoryList(name);
      } else {
        this.getList();
      }
    },
    getCategoryList(name) {
      let data = {
        site_id: -1,
        user_id: -1,
        type: this.typeId,
        category_id: this.curCategoryId,
      };

      this.loading = true;
      this.api.dataset.categoryForm(data).then((res) => {
        this.loading = false;
        if (name == 'typeId') {
          this.categoryList = [
            {
              name: '全部',
              id: '-1'
            },
            ...res.categoryFirstList
          ]
          this.secondCategoryList = [];
        } else if (name == 'curCategoryId') {
          this.secondCategoryList = [
            {
              name: '全部',
              id: '-1'
            },
            ...res.categorySecondList
          ]
        }
        this.getList();
        this.selectDataTabList = [];
        for (let name in res.categoryTypes) {
          this.selectDataTabList.push({
            id: name,
            name: res.categoryTypes[name]
          })
        }

      }).catch((err) => {
        this.loading = false;
      })
    },
  }
}
</script>

<style scoped lang="scss">
.role-main {
  margin: 20px;
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.4100);
  border-radius: 4px;

  .top {
    text-align: left;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top-left {
      width: 200px;
    }
  }

  .page {
    margin-top: 20px;
    text-align: right;
  }
}
.search {
    margin-top: 20px;
    margin-bottom: 20px;

    .search-item {
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name {
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }

      .cont {
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values {
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div {
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }

          >div.active {
            background-color: #2d8cf0;
            color: #FFFFFF;
            border-radius: 4px;
          }
        }

        .more {
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;

          .more-icon {
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }

.select-data-modal {
  .ivu-modal {
    width: 75% !important;
    min-width: 480px;
    max-width: 960px;
  }

  .tab-list {
    padding: 0 15px;

    .tab-list-top {
      display: inline-block;

      >div {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        border: 1px solid #dcdee2;
        border-radius: 4px;
        overflow: hidden;

        >div {
          width: 80px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          border-right: 1px solid #dcdee2;
          cursor: pointer;
          font-size: 14px;
        }

        >div:last-child {
          border-right: none;
        }

        >div.active {
          color: #FFFFFF;
          background: #2d8cf0;
        }
      }
    }

    .category-list-cont {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;


    }
  }
}
::v-deep .el-drawer {
  .el-drawer__header {
    margin-bottom: 0;
  }
}
</style>
